function Card({ backgroundImage, title }) {
  return (
    <div className="font-roboto">
      <div className="card">
        <div
          className="w-full h-60 bg-cover bg-center rounded overflow-hidden relative fade-in-up  border-cards"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      </div>
      <p className="text-center mt-2 text-xl font-bold">{title}</p>
      {/* <div className="flex flex-row justify-between px-12 font-bold">
        <a href="#" className="text-slate-500 underline">
          Link 1
        </a>
        <a href="#" className="text-slate-500 underline">
          Link 2
        </a>
      </div> */}
    </div>
  );
}

export default Card;
