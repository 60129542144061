import React from "react";
import gold1 from "../../../media/gold/gold1.jpg";
import gold2 from "../../../media/gold/gold2.jpg";
import gold3 from "../../../media/gold/gold3.jpg";
import gold4 from "../../../media/gold/gold4.jpg";

export default function GoldImages() {
  const array = [gold1, gold2, gold3, gold4];
  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 w-full h-36 md:px-12 gap-y-4">
      {array.map((item) => {
        return (
          <div className="lg:px-6 px-3">
            <img
              src={item}
              alt="gold-image-bar"
              className="h-full object-cover rounded-lg"
            />
          </div>
        );
      })}
    </div>
  );
}
