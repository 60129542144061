import FrontMain from "./Components/Front/main";
import "./App.css";

function App() {
  return (
    <div className="App">
      <FrontMain />
    </div>
  );
}

export default App;
