import GoldImages from "./GoldImages";

function GoldSection() {
  return (
    <div className="md:mb-36 mb-60">
      <h1 class="mt-20 mb-16 text-4xl font-xbold leading-none tracking-tight text-dark md:text-5xl lg:text-6xl text-center">
        <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">
          Precious Metals
        </span>
      </h1>

      <section className="relative pt-12 mb-20 font-roboto">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="gold banner"
              className="max-w-full rounded-lg shadow-lg border border-solid"
              src="https://img.freepik.com/free-photo/high-angle-aesthetic-wallpaper-with-gold-bars_23-2149872246.jpg?w=420"
            />
          </div>

          <div className="w-full md:w-5/12 mr-auto mb-4 px-4 lg:px-0">
            <div className="md:pr-12">
              <h3 className="text-3xl font-semibold text-center md:text-left mt-6 lg:mt-0">
                www.DirectGold.store
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500 text-justify">
                DirectGold.store is the go-to place for genuine, high-quality
                gold. We understand the unmatched value of this precious metal
                and offer a trustworthy and seamless shopping experience. With a
                strong emphasis on product authenticity, we guarantee that every
                piece meets the highest standards. Whether you're a seasoned
                investor or new to gold, our diverse collection is designed to
                meet your needs, making your purchasing process both easy and
                satisfying.
              </p>
            </div>
            <div className="pt-4">
              <a
                href="https://directgold.store"
                className="pt-4 text-purple-500 font-bold hover:underline hover:text-blue-600 ease-linear transition-all duration-150"
              >
                {" "}
                Visit DirectGold
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="md:mt-12 w-full">
        <GoldImages />
      </div>
    </div>
  );
}

export default GoldSection;
