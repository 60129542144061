function EnergySection() {
  return (
    <div className="mb-20">
      <h1 class="mt-20 mb-16 text-4xl font-xbold leading-none tracking-tight text-dark md:text-5xl lg:text-6xl text-center">
        <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">
          Energy Industries
        </span>
      </h1>

      <section className="relative pt-12 mb-20 font-roboto">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-5/12 ml-0 lg:ml-20 mb-4 px-6 lg:px-0">
            <div className="md:pl-12">
              <h3 className="text-3xl font-semibold hidden lg:block">
                Energy Industry
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500 text-justify">
                At the heart of our energy industry lies a steadfast commitment
                to sustainability. We harness the power of green and renewable
                energy sources to fuel our operations, not just as a nod to
                current trends, but as early adopters and fervent supporters.
                Our dedication to a greener planet is unwavering, and we are
                proud to be at the forefront of an energy revolution that
                prioritizes the environment without compromising on efficiency.
              </p>
            </div>
            <div className="pt-4 pl-12">
              <a
                href="https://directgold.store"
                className="m pt-4 text-purple-500 font-bold hover:underline hover:text-blue-600 ease-linear transition-all duration-150"
              >
                {" "}
                Visit
              </a>
            </div>
          </div>
          <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="A descriptive alt text"
              className="max-w-full rounded-lg shadow-lg border border-solid"
              src="https://img.freepik.com/free-photo/wind-power-plant-solar-pannels-low-angle_23-2149352231.jpg?w=420"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default EnergySection;
