import React from "react";

export default function TaxiSection({
  backgroundImage,
  title,
  description,
  list,
}) {
  return (
    <div className="flex flex-col w-full font-roboto bg-white h-full overflow-hidden">
      <div className="px-6 pt-6 h-full">
        <p className="text-xl font-xbold text-left mb-3">{title}</p>
        {/* <p className="text-center text-lg font-medium">{location}</p> */}
        <div>
          {description ? (
            <p className="text-left text-base font-normal mb-3">
              {description}
            </p>
          ) : null}
          {list ? (
            <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      <div className="flex items-center">
        <button
          type="button"
          className="py-2 px-5 mx-3 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-3"
        >
          Visit
        </button>
      </div>
    </div>
  );
}
