import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

export default function TaxiTop() {
  const imageArray = [
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-028-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-031-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-016-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-047-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-033-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-024-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-050-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-048-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-033-1920w.jpg",
    "https://le-de.cdn-website.com/a85e52d5e11c4aefa1ab1af420f2deaa/dms3rep/multi/opt/le-taxi-004-1920w.jpg",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {imageArray.map((item, index) => (
        <div
          key={index}
          className="w-full font-roboto bg-white h-full overflow-hidden"
        >
          <div>
            <img
              src={item}
              alt="scarpino taxi image"
              className="h-60 w-full card"
            />
          </div>
        </div>
      ))}
    </Slider>
  );
}
