import React from "react";

export default function Card2({
  backgroundImage,
  title,
  location,
  other,
  list,
  link,
}) {
  console.log(list);
  return (
    <div className="flex flex-col w-full font-roboto h-full bg-white overflow-hidden">
      <a href={link}>
        <img src={backgroundImage} alt={title} className="h-60 w-full card" />
      </a>
      <div className="bg-white p-6 h-full">
        <a href={link}>
          <p className="text-xl font-xbold text-left mb-3">{location}</p>
        </a>
        {/* <p className="text-center text-lg font-medium">{location}</p> */}
        <div>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex items-center">
        <a
          href={link}
          className="py-2 px-5 mx-3 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-3 text-center"
        >
          Visit
        </a>
      </div>
    </div>
  );
}
