import React from "react";

export default function SeekmeSection() {
  return (
    <div className="w-full h-full flex justify-center">
      <div className="lg:w-2/4 w-4/5 px-12 h-fit seekme-border">
        <p className="mb-6 text-center mt-6 font-roboto font-xbold font-light text-2xl">
          Interested in AI? Visit{" "}
          <a
            href="https://seekme.ai/"
            className="text-pink-800"
            target="_blank"
          >
            Seekme.ai
          </a>{" "}
          - the AI Encyclopedia{" "}
        </p>
        <a href="https://seekme.ai/" target="_blank">
          <img
            src={require("../../../media/seekmelogo.png")}
            alt="seekme.ai logo"
            className="h-30 w-full mb-6"
          />
        </a>
      </div>
    </div>
  );
}
