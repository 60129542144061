import Card from "./card";
import image1 from "../../media/images/leologi.jpeg";
import image2 from "../../media/images/taxi.jpeg";
import image3 from "../../media/images/gold.jpeg";
import image4 from "../../media/images/energy.jpeg";
// import bgvideo from "../../media/bg-video.mp4";
import bgImage from "../../media/bg-image.jpg";
import Navbar from "../Navbar/nav";
import apt1 from "../../media/apt-1.jpg";
import apt2 from "../../media/apt-2.jpg";
import apt3 from "../../media/apt-3.jpg";
import Card2 from "./card-page-2/Card2";
import GoldSection from "./gold/Gold-Section";
import TaxiSection from "./Taxi/TaxiSection";
import texiData from "./Taxi/taxi.json";
import EnergySection from "./Energy/EnergySection";
import Footer from "../Footer/Footer";
import TaxiTop from "./Taxi/TaxiTop";
import SeekmeSection from "./Seekme/SeekmeSection";

export default function FrontMain() {
  // scroll function
  const scrollToDiv = (ele) => {
    const element = document.getElementById(ele);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const cardImages = [
    { image: image1, title: "Apartments", id: "apartments" },
    { image: image2, title: "Transportation", id: "taxi-section" },
    { image: image3, title: "Precious Metals", id: "gold-section" },
    { image: image4, title: "Energy Industries", id: "energy-section" },
  ];

  const card2details = [
    {
      title: "Direct Booking Portugal",
      location: "Portugal",
      other: "other",
      backgroundImage: apt1,
      list: [
        "Room 1: 1 Double bed",
        "Room 2: 2 Single beds",
        "Room 3: 2 Single bed",
        "Parking, Terrece, Air-conditioning",
      ],
      link: "https://www.silvercoasthospitality.com/en/rentals/house-serra-do-bouro-casa-claudio-holiday-home-with-3-bedrooms-and-private-pool-sch078-384104.html",
    },
    {
      title: "Italy apartment 1",
      location: "Italy",
      other: "other",
      backgroundImage: apt2,
      list: [
        "A bedroom with a double bed",
        "A living room with a double sofa bed with fireplace",
        "A complete bathroom with shower box",
        "Garage, Store room, Washing machine",
      ],
      link: "https://maisoncarrelelegant.com/en/apartments/maison-carrel-elegant-8/",
    },
    {
      title: "Italy apartment 2",
      location: "Italy",
      other: "other",
      backgroundImage: apt3,
      list: [
        "A bedroom with a double bed",
        "A living room with a double sofa bed with fireplace",
        "A complete bathroom with shower box",
        "Garage, Store room, Washing machine",
      ],
      link: "https://maisoncarrelelegant.com/en/apartments/maison-carrel-elegant-7/",
    },
  ];

  return (
    <div>
      <Navbar></Navbar>
      <div className="bg-white flex flex-row flex-wrap justify-center items-center">
        <h1 class="mt-12 text-4xl font-xbold leading-none tracking-tight text-dark md:text-5xl lg:text-6xl text-center">
          <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">
            Our Industries
          </span>
        </h1>
        {/* Content */}
        <div className="p-3 lg:p-12 mt-12 mb-20 w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-6 justify-top items-top mx-auto z-10">
          {cardImages.map((img, index) => (
            <div
              onClick={() => scrollToDiv(img.id)}
              key={index + 1}
              className="cursor-pointer"
            >
              <Card key={index} backgroundImage={img.image} title={img.title} />
            </div>
          ))}
        </div>
        <div className="bg-2nd p-12 w-full " id="apartments">
          <h1 class="mb-28 mt-10 text-4xl font-xbold leading-none tracking-tight text-white md:text-5xl lg:text-6xl text-center">
            <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">
              Holiday Homes
            </span>
          </h1>
          <div className="w-full h-full grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 justify-center items-center mb-20">
            {card2details.map((card, index) => (
              <Card2
                key={index}
                backgroundImage={card.backgroundImage}
                title={card.title}
                location={card.location}
                other={card.other}
                list={card.list}
                link={card.link}
              />
            ))}
          </div>
        </div>
        {/* Gold section */}
        <div id="gold-section">
          <GoldSection />
        </div>
        {/* seekme */}
        <div className="mb-24 w-full h-full rounded-2xl">
          <SeekmeSection />
        </div>
        {/* Taxi section */}
        <div className="bg-slate-700 p-12 w-full" id="taxi-section">
          <h1 class="mb-28 mt-10 text-4xl font-xbold leading-none tracking-tight text-white md:text-5xl lg:text-6xl text-center">
            <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">
              Transportation Services
            </span>
          </h1>
          <div className="w-full h-full justify-center items-center mb-20">
            <div className="mb-12">
              <TaxiTop />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6 justify-center items-center mb-20">
              {texiData.map((card, index) => (
                <TaxiSection
                  key={index}
                  backgroundImage={card.image}
                  title={card.title}
                  description={card.description}
                  list={card.list}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Energy section */}
        <div id="energy-section">
          <EnergySection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
